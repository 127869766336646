import React from 'react'
import Header from '../components/header'
import Footer from "../components/footer"
import "../style/dropMenu.less"
import logo from "../images/blog/logo.png"
import envelope from "../images/blog/envelope.png"
export default function dropMenu() {

    
  return (
    
        <ul className='dropMenu'>
            <li>
                <h3>Company</h3>
                <p>Building Enterprise Application Frameworks and Products for decades.</p>
            </li>
            <li>
                <div>
                    <div><img  src={logo} alt="" /></div>
                    <div>About us</div>
                </div>
                <div>
                    <div><img  src={envelope} alt="" /></div>
                    <div>Contact us</div>
                </div>
            </li>
        </ul>
        
  )
}
